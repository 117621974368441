body {
  margin: 0;
  padding: 0;
  background: #2e2e2e;
}

::selection{
  background-color: #2e2e2e;
}

.App {
  position: relative;
  height: 100vh;
  font-family: 'Oswald', sans-serif;
  color: #fff;
}

.overlay.overlay-dark-30-video {
  background-color: rgba(0, 0, 0, .3);
  width: 100%;
  height: 100%;
  position: absolute;
}

.center-container {
  z-index: 2;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
  text-align: center;
}

.title {
  font-size: 5rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  letter-spacing: -0.02em;
}


.subtitle {
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  line-height: 1.1;
  font-weight: 400;
}

@media only screen and (max-width: 880px) {
  h1.title {
    font-size: 85px;
  }
}

@media only screen and (max-width: 640px) {
  h1.title {
    font-size: 65px;
  }
}